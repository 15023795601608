    /* Set a style for the submit button */
    .logoutbtn {
        background-color: teal;
        color: white;
        padding: 16px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }
        
    }