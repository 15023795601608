.list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 76px;
}

.load-more-btn {
    width: 350px;
    padding: 10px;
    background-color: white;
    color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    border: teal 1px solid;
    font-weight: 700;
    margin: 0 auto;

    &:hover {
        background-color: teal;
        color: white;
        border: teal 1px solid;
    } 

    @media (max-width: 740px) {
        width: 100%;
    }
}