.navbar {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 9999999;

    .wrapper {
        padding: 10px 30px;
        display: flex;
        justify-content: space-between;

        .logo {
            width: 200px;
        }
        
        .item {
            display: flex;
            align-items: center;
            font-size: 18px;
        }
        .left {
            display: flex;
            align-items: center;
            gap: 25px;
            width: 168px;
        }
        .center {
            width: 200px;
            display: flex;

            a {
                width: 100%;
                display: flex;
                gap: 15px;
                color: #777;
                cursor: pointer;
                font-size: 24px;
                align-items: center;
            }
        }
        .right {
            display: flex;
            align-items: center;
            gap: 25px;

            .icons {
                display: flex;
                gap: 15px;
                color: #777;
                cursor: pointer;
                font-size: 24px;
                align-items: center;

                a {
                    color: #777;
                }

                .cartIcon {

                    position: relative;

                    span {
                        font-size: 12px;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background-color: #941a1a;
                        color: white;
                        position: absolute;
                        right: -10px;
                        top: -10px;
                        display: flex;
                        align-items: center; 
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .navbar {
        .wrapper {
            .logo {
                width: 100%;
            }

            display: block;

            .center {
                margin: 0 auto;
                a {
                    display: block;
                    width: 130px;
                    margin: 0 auto;
                }
            }

            .right {
                justify-content: center;
            }

            .left {
                display: flex;
                align-items: center;
                gap: 25px;
                justify-content: center;
                margin: auto;
            }

            .item {
                font-size: 15px;
            }
        }
    }
}