.footer {
    margin: 100px 200px 20px 200px;

    .top {
        display: flex;
        gap: 50px;

        .item{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            text-align: left;
            font-size: 14px;

            h1 {
                font-size: 18px;
                font-weight: 500;
                color: #555;
            }

            span {
                color: gray;
                a {
                    color: gray;
                    text-decoration: none;
                }
            }
        }
    }
    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        .left {
            display: flex;
            align-items: center;

            .logo {
                width: 160px;
            }

            .copyright {
                margin-left: 20px;
                font-size: 12px;
                color: gray;
            }
        }
        .right {
            img {
                height: 50px;
            }
        }
    }
}

@media (max-width: 960px) {
    .footer {
        margin: 40px 50px 20px 50px;
        .top {
            display: block;
            text-align: center;

            .item {
                text-align: center;
                margin-bottom: 30px;
            }
        }
        
        .bottom {
            display: block;
            .left {
                .logo {
                    margin: 0 auto;
                    margin-bottom: 20px;
                    display: flex;
                }
                display: block;
                .copyright {
                    display: flex;
                    justify-content: center;
                    margin: 0;
                }
            }

            .right {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}