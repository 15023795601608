.product {
    padding: 20px 50px;
    display: flex;
    gap: 50px;

    .loading {
        display: flex;
        justify-content: center;
    }

    .left {
        flex: 1;
        display: flex;
        gap: 20px;
        .images{
            flex: 1;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                cursor: pointer;
                margin-bottom: 10px;
            }
        }
        .mainImg{
            flex: 5;

            img {
                width: 100%;
                max-height: 800px;
                object-fit: cover;
            }
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .price {
            font-size: 30px;
            color: teal;
            font-weight: 500;
        }

        p {
            font-size: 18px;
            font-weight: 300;
            text-align: justify;
        }

        .quantity {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
            }
        }

        .links {
            display: flex;
            gap: 20px;        
            flex-wrap: wrap;
            flex-direction: row;


            a {
                text-decoration: none;
            }

            .add {
                width: 177px; 
                padding: 10px;
                background-color: white;
                color: teal;
                align-items: center;
                justify-content: center;
                gap: 10px;
                cursor: auto;
                border: 1px solid teal;
                font-weight: 700;
                display: block;
                text-align: center;

                .title {
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: teal;
                }
            }

            .navigate-link { 
                padding: 10px;
                background-color: teal;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
                cursor: pointer;
                border: none;
                font-weight: 500;
                border-bottom: 1px dotted #fff;
            }

            .images {
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
        }

        .add {
            width: 100%; 
            padding: 10px;
            background-color: teal;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            border: none;
            font-weight: 500;
        }

        .links {
            display: flex;
            gap: 20px;

            .item {
                display: flex;
                align-items: center;
                gap: 10px;
                color: teal;
                font-size: 14px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            color: gray;
            font-size: 14px;

            hr {
                width: 200px;
                border: 1px solid lightgray;
            }
        }

        hr {
            border: 1px solid lightgray;
        }
    }
}

@media (max-width: 960px) {
    .product {
        display: block;

        .loading {
            display: flex;
            justify-content: center;
        }
        
        .left {
            display: flex;
            flex-direction: column-reverse;

            .images {
                display: flex;
                gap: 10px;
                img {
                    width: 100px;
                }
            }
        }
    }
}

@media (max-width: 740px) {
    .product {
        display: block;
        padding: 20px 20px;
        
        .left {
            display: flex;
            flex-direction: column-reverse;

            .images {
                display: flex;
                gap: 10px;
                img {
                    width: 100px;
                }
            }
        }

        .right {
            .links {
                display: grid;
                grid-template-columns: 1fr 1fr;
                .add {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .product {

        .right {
            .links {
                grid-template-columns: 1fr;
            }
        }
    }
}