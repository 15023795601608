/* Add padding to containers */
* {
    box-sizing: border-box;
}

.thankyou {
    margin: 250px auto;
    display: block;
    h1 {
        text-align: center;
        display: block;
    }

    /* Add a blue text color to links */
    a {
        display: block;
        color: #000;
        margin: 0 auto;
        text-align: center;
    }
}